import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page--limited card" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "p-d-flex p-ai-center" }
const _hoisted_4 = { class: "export_action p-mb-4" }
const _hoisted_5 = { class: "p-p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_TicketStatus = _resolveComponent("TicketStatus")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BackButton, { to: _ctx.backRoute }, null, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('ticket.title_edit')) + " ", 1),
          _createElementVNode("span", null, "#" + _toDisplayString(_ctx.ticketId), 1)
        ])
      ]),
      _createElementVNode("div", null, [
        (_ctx.ticket)
          ? (_openBlock(), _createBlock(_component_TicketStatus, {
              key: 0,
              status: _ctx.ticket.status,
              class: "custom-chip--big p-ml-3"
            }, null, 8, ["status"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SplitButton, {
        icon: 'pi ' + (_ctx.exporting ? 'pi-spin pi-spinner' : 'pi-file-excel'),
        disabled: _ctx.exporting,
        label: "Export",
        model: _ctx.exports
      }, null, 8, ["icon", "disabled", "model"])
    ]),
    _createVNode(_component_TabMenu, { model: _ctx.tabs }, null, 8, ["model"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              ticketId: _ctx.ticketId,
              ticket: _ctx.ticket,
              onUpdate: _ctx.refresh,
              onSave: _ctx.update,
              onDeleted: _ctx.refresh
            }, null, 8, ["ticketId", "ticket", "onUpdate", "onSave", "onDeleted"]))
          ], 1024))
        ])
      ]),
      _: 1
    })
  ]))
}