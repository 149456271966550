
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { plainToClass } from "class-transformer";
import { Options, Vue } from "vue-class-component";
import { Model } from "vue-property-decorator";

import moment from "moment";

import {
  UserAutocomplete, ShiftAutocomplete, ContractAutocomplete, ZoneAutocomplete, CompanyAutocomplete
} from "@components";

import { TicketFilters } from "./TicketFilters";
import { ZoneTypeEnum } from "@/model/enums/ZoneTypeEnum";
import { authStore } from "@/modules/auth/store";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    ContractAutocomplete,
    ZoneAutocomplete,
    UserAutocomplete,
    ShiftAutocomplete,
    CompanyAutocomplete
  },
})
export default class extends Vue {
  @Model("filters")
  advFilters: TicketFilters;

  onSelectedUser() {
    this.emitFilters();
  }

  onSelectedShift() {
    this.emitFilters();
  }

  onSelectedZone() {
    this.emitFilters();
  }

  onSelectedDumpSite() {
    this.emitFilters();
  }

  onSelectedContract() {
    this.emitFilters();
  }

  onCustomerSelected() {
    this.emitFilters();
  }

  onBrokerSelected() {
    this.emitFilters();
  }

  onClientSelected() {
    this.emitFilters();
  }

  openCalendar(event) {
    (this.$refs as any).op?.toggle(event);
  }

  hideCalendar() {
    (this.$refs as any).op?.hide();
  }

  resetZoneLoad() {
    this.advFilters.zone_load = null;
    this.emitFilters();
  }

  resetDumpSite() {
    this.advFilters.zone_dump = null;
    this.emitFilters();
  }

  resetDateFilter() {
    this.advFilters.from_to = null;
    this.emitFilters();
  }

  get fromDate() {
    const from = this.advFilters?.from_to?.[0];

    return from ? moment(from).format("YYYY/MM/DD") : "----/--/--";
  }

  get toDate() {
    const to = this.advFilters?.from_to?.[1];

    return to ? moment(to).format("YYYY/MM/DD") : "----/--/--";
  }

  get zoneTypeLoad() {
    return ZoneTypeEnum.LOAD;
  }

  get zoneTypeDump() {
    return ZoneTypeEnum.DUMP;
  }
  
  get isSuperAdmin() {
    return authStore.getters.me.role == UserRoleEnum.SUPER_ADMIN
  }

  emitFilters() {
    this.saveAdvancedFilters();
    this.$emit("change", this.advFilters);
  }

  private saveAdvancedFilters() {
    sessionStorage.setItem(
      SessionStorageEnum.TICKETS_ADVANCED_FILTERS,
      JSON.stringify(this.advFilters)
    );
  }

  private restoreAdvancedFilters() {
    const fromStorage = JSON.parse(
      sessionStorage.getItem(SessionStorageEnum.TICKETS_ADVANCED_FILTERS)
    );

    this.advFilters = fromStorage
      ? plainToClass(TicketFilters, fromStorage)
      : this.advFilters;
  }

  created() {
    this.restoreAdvancedFilters();
  }
}
