import {Ticket} from "@/model/api/Ticket";
import {activeStatuses, TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import {TicketsRoutesEnum} from "@/modules/tickets/router";
import {ticketsService} from "@services/tickets.service";
import moment from "moment";
import {Options, Prop, Vue, Watch} from "vue-property-decorator";

import {
  ContractAutocomplete,
  FieldsetSkeleton,
  MomentZoneCalendar,
  TicketDriverData,
  TicketHistory,
} from "@components";

import TicketFinishStatus from '@/components/TicketFinishStatus/TicketFinishStatus.vue';
import TicketStatus from "@/components/TicketStatus/TicketStatus.vue";
import ZoneCard from "@/components/ZoneCard/ZoneCard.vue";

import TicketDisputeResolverDialog from "@/components/TicketDisputeResolverDialog/TicketDisputeResolverDialog.vue";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";
import {Contract} from "@/model/api/Contract";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {PaymentStatusEnum} from "@/model/enums/PaymentStatusEnum";
import {authStore} from "@/modules/auth/store";
import {AccessesBySection, UserRoleEnum} from "@/model/enums/UserRoleEnum";

@Options({
  name: 'TicketPage',

  components: {
    MomentZoneCalendar,
    TicketDisputeResolverDialog,
    TicketDriverData,
    TicketHistory,
    ContractAutocomplete,
    TicketFinishStatus,
    TicketStatus,
    ZoneCard,
    FieldsetSkeleton
  }
})
export default class TicketData extends Vue {

  @Prop() readonly ticketId!: String;

  @Prop() readonly ticket!: Ticket;


  get isNew() {
    return this.ticketId === 'new';
  }

  get backRoute() {
    return { name: TicketsRoutesEnum.TICKETS_LIST }
  }

  get shiftRoute() {
    return ShiftsRoutesEnum.SHIFTS_DETAIL;
  }

  get paymentStatusOptions() {
    return [
      { label: this.$t('ticket.payment_statuses.PENDING'), value: PaymentStatusEnum.PENDING },
      { label: this.$t('ticket.payment_statuses.APPROVED'), value: PaymentStatusEnum.APPROVED },
      { label: this.$t('ticket.payment_statuses.AUTHORIZED'), value: PaymentStatusEnum.AUTHORIZED },
      { label: this.$t('ticket.payment_statuses.PAID'), value: PaymentStatusEnum.PAID },
    ]
  }

  get duration() {
    const startMoment = moment(this.ticket.datetime_start);
    let d = null;

    if (!this.ticket.datetime_end.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(this.ticket.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());

    const htext = this.$t('hours', h).trim();
    const mtext = this.$t('minutes', m).trim();

    return `${h} ${htext}` + (m ? ` ${m} ${mtext}` : '');
  }

  get hasEndDate() {
    return this.ticket?.datetime_end?.isValid();
  }

  get isClosed() {
    return [
      TicketStatusEnum.DELETED_BY_SYSTEM,
      TicketStatusEnum.DELETED_BY_USER,
      TicketStatusEnum.CLOSED,
    ].includes(this.ticket.status);
  }

  get isReadOnly() {
    const me = authStore.getters.me
    return !AccessesBySection.TICKET_EDIT.includes(me.role) || this.ticket.mov_id || (me.role == UserRoleEnum.CUSTOMER && this.ticket.customer_id != me.customer_id)
  }

  get ticketPaidOrAuthorized(){
    return this.ticket.payment_status === PaymentStatusEnum.APPROVED || this.ticket.payment_status === PaymentStatusEnum.PAID
  }

  get isBroker() {
    return authStore.getters.me.role == UserRoleEnum.BROKER;
  }

  get isCustomer() {
    return authStore.getters.me.role == UserRoleEnum.CUSTOMER;
  }

  get isSuperAdmin() {
    return authStore.getters.me.role == UserRoleEnum.SUPER_ADMIN;
  }

  get isBtnSaveTicketDataVisible() {
    return (this.isClosed || [TicketStatusEnum.ACCEPTED_BY_USER].includes(this.ticket.status)) && !this.ticketPaidOrAuthorized;
  }

  get showActions() {
    return activeStatuses.includes(this.ticket?.status);
  }

  get canDelete() {
    return [TicketStatusEnum.ACCEPTED_BY_USER, TicketStatusEnum.DELETED_BY_USER, TicketStatusEnum.DELETED_BY_SYSTEM].includes(this.ticket.status);
  }

  get isAcceptedByUser() {
    return [TicketStatusEnum.ACCEPTED_BY_USER].includes(this.ticket.status);
  }

  get isDeleted() {
    return this.ticket?.status === TicketStatusEnum.DELETED_BY_USER || this.ticket?.status === TicketStatusEnum.DELETED_BY_SYSTEM;
  }

  get isDispute() {
    return this.ticket?.status === TicketStatusEnum.DISPUTE;
  }

  get canSeeTotalTon() {
    return this.ticket.shift.payment_type === PaymentTypeEnum.TON || this.ticket.shift.is_ton_requested == true;
  }

  get hiddenHistoryColumns() {
    const columnHeaders = {}
    if (this.ticket.shift.payment_type !== PaymentTypeEnum.TON && !this.ticket.shift.is_ton_requested) {
      columnHeaders['ton_image_path_url'] = true
      columnHeaders['ton'] = true
    }
    if (!this.ticket.shift.can_upload_photo_load && !this.ticket.shift.can_upload_photo_dump) {
      columnHeaders['can_upload_photo'] = true
    }
    return columnHeaders;
  }

  get isGoingToLoad() {
    return [
      TicketStatusEnum.DUMPED,
      // TicketStatusEnum.WAITING_LOAD,
    ].includes(this.ticket?.status);
  }

  get isGoingToDump() {
    return [
      TicketStatusEnum.LOADED,
      // TicketStatusEnum.WAITING_DUMP,
    ].includes(this.ticket?.status);
  }

  onContractSelect(contract: Contract) {
    if (contract && contract.id) {
      this.ticket.contract_id = contract?.id;
    } else {
      delete this.ticket.contract_id
    }
  }

  get freeDumpRequestVisible(){
    return (
        authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN || authStore.getters.me.role === UserRoleEnum.CUSTOMER) &&
        (!this.ticket.free_dump_accepted_at && this.ticket.free_dump_requested_at)
  }

  free_dump_accepting: boolean = false
  async  acceptFreeDumpRequest(){
    this.free_dump_accepting = true
    try {
      const ticket: Ticket = await ticketsService.acceptFreeDumpRequest(this.ticket.id);
      if(ticket.free_dump_accepted_at){
        this.ticket.free_dump_accepted_at = ticket.free_dump_accepted_at
      }
    } catch (error: any) {
      this.$errorMessage('Accepting failed');
    } finally {
      this.free_dump_accepting = false
    }
  }

  isCalcFeeInProgress = false;
  async calcFee() {
    this.isCalcFeeInProgress = true

    try {
      const response = await ticketsService.calcFee(this.ticket);
      this.ticket.fee_driver = response.fee_driver;
      this.ticket.fee_broker = response.fee_broker;
      this.ticket.fee_client = response.fee_client;
      this.ticket.fee_customer = response.fee_customer;
      this.ticket.fee_system = response.fee_system;

      this.isCalcFeeInProgress = false

    } catch (error: any) {
      if (error?.status === 422) {
        const fieldsInError = Object.keys(error.data.errors);

        fieldsInError.forEach(k => {
          this.$errorMessage(error.data.errors[k][0], k.toUpperCase());
        });
      }

      if (error) {
        this.isCalcFeeInProgress = false
      }
    }
  }

  onResolved() {
    this.displayResolverDialog = false;
    this.$emit('update:visible', false);

    this.$successMessage(
      this.isDispute
        ? this.$t("ticket.messages.dispute_resolved")
        : this.$t("ticket.messages.close_success")
    );

    this.$emit('update');
  }

  onResolveCancel() {
    this.displayResolverDialog = false;
  }

  get trashLabel() {
    return this.ticket.status === TicketStatusEnum.ACCEPTED_BY_USER ? this.$t('ticket.actions.decline') : this.$t('ticket.actions.delete')
  }

  onDelete() {

    if (this.ticket.status === TicketStatusEnum.ACCEPTED_BY_USER) {

      this.$confirmMessage(this.$t('ticket.messages.decline_confirm')).then(r => {
        if (r) {
          this.deleteTicket();
        }
      })
    } else {
      this.$confirmMessage(this.$t('ticket.messages.delete_confirm')).then(r => {
        if (r) {
          this.deleteTicket(true);
        }
      })
    }

  }

  private deleteTicket(standard?) {
    this.$waitFor(
      async () => {
        if (!standard) {
          await ticketsService.deleteBySystem(this.ticket);
        } else {
          await ticketsService.deleteStandard(this.ticket);
        }
        this.$emit('deleted');
      },
      "Deleting Ticket failed"
    )
  }


  async updatePayment() {
    try {
      this.requestPending = true;

      await ticketsService.updatePatch({
        id: this.ticket.id,
        payment_status: this.ticket.payment_status,
      });

      this.$successMessage(this.$t('ticket.messages.update_success'));
    } catch (error) {
      this.$errorMessage(this.$t('ticket.messages.update_fail'));
    } finally {
      this.requestPending = false;
    }
  }

  async update() {
    if (!this.ticket.contract_id) {
      this.$errorMessage("Contract is required")
      return;
    }

    try {
      this.requestPending = true;

      await ticketsService.updatePatch({
        id: this.ticket.id,
        datetime_start: this.ticket.datetime_start,
        datetime_end: this.ticket.datetime_end,
        datetime_assigned: this.ticket.datetime_assigned,
        broker_deduct: parseInt('' + this.ticket.broker_deduct),
        broker_deduct_note: this.ticket.broker_deduct_note,
        total_ton: this.ticket.total_ton || null,
        contract_id: this.ticket.contract_id,
        cycles: this.ticket.cycles,
        extra_fee_driver      : this.ticket.extra_fee_driver || 0,
        extra_fee_broker      : this.ticket.extra_fee_broker || 0,
        extra_fee_system      : this.ticket.extra_fee_system || 0,
        extra_fee_customer    : this.ticket.extra_fee_customer || 0
      });

      this.$successMessage(this.$t('ticket.messages.update_success'));
    } catch (error) {
      this.$errorMessage(this.$t('ticket.messages.update_fail'));
    } finally {
      this.requestPending = false;
    }
  }

  displayResolverDialog: boolean = false;
  dialogHeader: string = null;

  closeTicket() {
    this.dialogHeader = "Close ticket - Refund";
    this.displayResolverDialog = true;
  }

  disputeTicket() {
    this.dialogHeader = "Resolve dispute - Refund";
    this.displayResolverDialog = true;
  }

  onConfirm() {
    this.displayResolverDialog = false;

  }
  onCancel() {
    this.displayResolverDialog = false;
  }

  @Watch('ticket.datetime_start')
  onStarTimeChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.datetime_end')
  onEndTimeChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.cycles')
  onCyclesChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.contract')
  onContractChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.total_ton')
  onTotalTonChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.extra_fee_driver')
  onExtraFeeDriverChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.extra_fee_customer')
  onExtraFeeCustomerChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.extra_fee_broker')
  onExtraFeeBrokerChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.extra_fee_system')
  onExtraFeeSystemChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  @Watch('ticket.broker_deduct')
  onBrokerDeductChange(newValue, oldValue) {
    if (newValue == null || oldValue == null) return
    this.calcFee()
  }

  async updatePrivateCloseoutNote() {
    try {
      this.requestPending = true;

      await ticketsService.updatePatch({
        id: this.ticket.id,
        private_closeout_note: this.ticket.private_closeout_note,
      });

      this.$successMessage(this.$t('ticket.messages.update_success'));
    } catch (error) {
      this.$errorMessage(this.$t('ticket.messages.update_fail'));
    } finally {
      this.requestPending = false;
    }
  }

}
