import { Type } from "class-transformer";

import { Contract } from "@/model/api/Contract";
import { Shift }    from "@/model/api/Shift";
import { User }     from "@/model/api/User";
import {Zone} from "@/model/api/Zone";
import { Company } from "@/model/api/Company";

export class TicketFilters {
    @Type(() => Date)
    from_to: Date[];

    get from() {
        return this.from_to?.[0];
    }

    get to() {
        return this.from_to?.[1];
    }

    user: User;

    shift: Shift;

    zone_load: Zone;

    zone_dump: Zone;

    contract: Contract;

    customer: Company;
    broker: Company;
    client: Company;

    constructor() {
        const from = new Date();
        this.from_to = [from];
    }
}
