import { Ticket } from "@/model/api/Ticket";
import { TicketsRoutesEnum } from "@/modules/tickets/router";
import { Options, Prop, Vue } from "vue-property-decorator";
import {ticketsService} from "@services/tickets.service";

@Options({
  name: 'TicketGalley',

  components: {}
})
export default class TicketGallery extends Vue {

  @Prop() readonly ticketId!: String;

  @Prop() readonly ticket!: Ticket;


  get isNew() {
    return this.ticketId === 'new';
  }

  get backRoute() {
    return { name: TicketsRoutesEnum.TICKETS_LIST }
  }

  get files(): any[] {
    return this.ticket?.files;
  }

  uploadInProgress: boolean = false;

  selectedFile : File = null;

  get fileName() {
    return this.selectedFile?.name;
  }

  onAddPhoto() {
    (this.$refs.file_input as HTMLInputElement).click();
  }

  private async removeFile(fileId: number) {
    try {
      await ticketsService.deleteFile(fileId);

      const idx = this.ticket.files.findIndex(x => x.id === fileId);
      this.ticket.files.splice(idx, 1);

    } catch (error) {
      this.$errorMessage("Deleting failed");
    }
  }

  private async uploadFile() {
    try {
      this.uploadInProgress = true;

      const response = await ticketsService.uploadFiles(
          this.ticket.id,
          this.selectedFile
      );

      this.selectedFile = null;

      this.ticket.files ??= [];
      this.ticket.files.push(response);

    } catch (error) {
      console.error(error);
      this.$errorMessage("Upload failed");
    } finally {
      this.uploadInProgress = false;
    }
  }

  onUpload() {
    this.uploadFile();
  }

  onRemoveFile(fileId: number) {
    this.removeFile(fileId);
  }

  onFilesSelect(event: Event) {
    const target = event.target as HTMLInputElement;
    this.selectedFile = target.files.length && target.files[0];
  }

}
