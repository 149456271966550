import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-50a47dd6")
const _hoisted_1 = { class: "toolbar__left" }
const _hoisted_2 = { class: "date_range" }
const _hoisted_3 = { class: "date_range__overlay" }
const _hoisted_4 = { class: "p-text-center" }
const _hoisted_5 = { class: "p-d-flex p-jc-center p-my-2" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_UserAutocomplete = _resolveComponent("UserAutocomplete")!
  const _component_ShiftAutocomplete = _resolveComponent("ShiftAutocomplete")!
  const _component_ContractAutocomplete = _resolveComponent("ContractAutocomplete")!
  const _component_ZoneAutocomplete = _resolveComponent("ZoneAutocomplete")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_CompanyAutocomplete = _resolveComponent("CompanyAutocomplete")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    class: "p-mb-3",
    header: "Filters",
    toggleable: true,
    collapsed: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            icon: "pi pi-calendar",
            onClick: _ctx.openCalendar
          }, null, 8, ["onClick"]),
          _createElementVNode("span", {
            class: "date_range__text",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCalendar && _ctx.openCalendar(...args)))
          }, " From: " + _toDisplayString(_ctx.fromDate) + " To: " + _toDisplayString(_ctx.toDate), 1),
          _createVNode(_component_OverlayPanel, {
            ref: "op",
            appendTo: "body"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("header", _hoisted_4, [
                  _createElementVNode("h5", null, "From: " + _toDisplayString(_ctx.fromDate) + " To: " + _toDisplayString(_ctx.toDate), 1)
                ]),
                _createVNode(_component_Calendar, {
                  modelValue: _ctx.advFilters.from_to,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.advFilters.from_to = $event)),
                  onDateSelect: _ctx.emitFilters,
                  selectionMode: "range",
                  showSeconds: false,
                  inline: true,
                  numberOfMonths: 2
                }, null, 8, ["modelValue", "onDateSelect"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    label: "Reset",
                    icon: "pi pi-times",
                    class: "p-button-info",
                    onClick: _ctx.resetDateFilter
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_Button, {
                    label: "Ok",
                    icon: "pi pi-check",
                    class: "p-ml-3",
                    onClick: _ctx.hideCalendar
                  }, null, 8, ["onClick"])
                ])
              ])
            ]),
            _: 1
          }, 512)
        ]),
        _createElementVNode("div", null, [
          (_ctx.isSuperAdmin)
            ? (_openBlock(), _createBlock(_component_UserAutocomplete, {
                key: 0,
                modelValue: _ctx.advFilters.user,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.advFilters.user = $event)),
                onSelect: _ctx.onSelectedUser
              }, null, 8, ["modelValue", "onSelect"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_ShiftAutocomplete, {
            modelValue: _ctx.advFilters.shift,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.advFilters.shift = $event)),
            onSelected: _ctx.onSelectedShift,
            "shift-key": "code"
          }, null, 8, ["modelValue", "onSelected"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_ContractAutocomplete, {
            modelValue: _ctx.advFilters.contract,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.advFilters.contract = $event)),
            onSelect: _ctx.onSelectedContract
          }, null, 8, ["modelValue", "onSelect"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_FloatLabel, {
            label: _ctx.$t('shift.load_site')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ZoneAutocomplete, {
                dropdown: true,
                modelValue: _ctx.advFilters.zone_load,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.advFilters.zone_load = $event)),
                onSelect: _ctx.onSelectedZone,
                onClear: _ctx.resetZoneLoad
              }, null, 8, ["modelValue", "onSelect", "onClear"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_FloatLabel, {
            label: _ctx.$t('shift.dump_site')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ZoneAutocomplete, {
                dropdown: true,
                modelValue: _ctx.advFilters.zone_dump,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.advFilters.zone_dump = $event)),
                onSelect: _ctx.onSelectedDumpSite,
                onClear: _ctx.resetDumpSite
              }, null, 8, ["modelValue", "onSelect", "onClear"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _createElementVNode("div", null, [
          (_ctx.isSuperAdmin)
            ? (_openBlock(), _createBlock(_component_CompanyAutocomplete, {
                key: 0,
                label: "Customer",
                type: 300,
                modelValue: _ctx.advFilters.customer,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.advFilters.customer = $event)),
                onSelect: _ctx.onCustomerSelected,
                showDropdown: true
              }, null, 8, ["modelValue", "onSelect"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_CompanyAutocomplete, {
            label: "Broker",
            type: 400,
            modelValue: _ctx.advFilters.broker,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.advFilters.broker = $event)),
            onSelect: _ctx.onBrokerSelected,
            showDropdown: true
          }, null, 8, ["modelValue", "onSelect"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_CompanyAutocomplete, {
            label: "Client",
            type: 200,
            modelValue: _ctx.advFilters.client,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.advFilters.client = $event)),
            onSelect: _ctx.onClientSelected,
            showDropdown: true
          }, null, 8, ["modelValue", "onSelect"])
        ])
      ])
    ]),
    _: 1
  }))
}