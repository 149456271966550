import { markRaw, toRaw }        from "vue";
import { Options, Vue }          from "vue-class-component";
import { Prop, Watch }           from "vue-property-decorator";

import moment                    from "moment";
import timezone                  from "moment-timezone";

import { FilterMatchMode }       from "primevue/api";

import { ticketsService }        from "@services/tickets.service";
import { trackingService }       from "@services/traking.service";

import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import { Ticket, TicketHistory } from "@/model/api/Ticket";
import { Tracking }              from "@/model/api/Traking";

import {
  TableSkeleton, 
  TicketStatus
} from "@components";
import {zonesService} from "@services/zones.service";
import {Zone} from "@/model/api/Zone";
import {ZoneTypeEnum} from "@/model/enums/ZoneTypeEnum";

@Options({
  inheritAttrs: false, 
  components: {
    TableSkeleton,
    TicketStatus
  },

  setup() {
    const loadShape: google.maps.Polygon = markRaw(null);
    const dumpShape: google.maps.Polygon = markRaw(null);

    return {
      loadShape,
      dumpShape
    }
  }
})
export default class TicketHistoryMap extends Vue {
  @Prop() readonly ticketId!: number;
  @Prop() readonly ticket!: Ticket;

  // @Prop() readonly timeZone! : string;

  get timeZone(): string {
    return this.ticket?.zone_name;
  }

  sliderIndex: number = 0;
  zones: { zone?, dump? } = {}
  sliderSteps: any[] = [];
  deactivatedTab: boolean;

  @Watch('sliderIndex')
  onSliderIdexChange(value, old) {
    const oldStepButton = this.sliderSteps.find((step) => step.index === old)
    const newStepButton = this.sliderSteps.find((step) => step.index === value)
    if (oldStepButton) {
      oldStepButton.selected = false
    }
    if (newStepButton) {
      newStepButton.selected = true
    }
    this._initMarker();
  }

  private moveMarker() {
    const point = this.tracking?.[this.sliderIndex]

    if (!point) {
      return;
    }

    const {lat, lng} = point;
    this.marker.setPosition(new google.maps.LatLng({lat, lng}));
  }

  private centerMapOnMarker() {
    this.map.setZoom(12);
    this.map.setCenter(this.marker.getPosition());
  }

  get selectedTime() {
    if (this.sliderIndex === null) return "";
    const date = this.tracking?.[this.sliderIndex]?.created_at;
    return timezone.tz(date, this.timeZone).format("YYYY/MM/DD hh:mm A");
  }

  get maxSlider() {
    return (this.tracking?.length - 1) || 0;
  }

  get TicketStatusEnum() {
    return TicketStatusEnum;
  }

  isValidStep(status: number): boolean {
    return status === TicketStatusEnum.LOADED || status === TicketStatusEnum.DUMPED
  }

  setSlider(status: number, stepIndex: number) {
    this.sliderIndex = stepIndex;
  }

  mapRef: google.maps.Map = null;

  marker: google.maps.Marker = null;

  get map() {
    return toRaw(this.mapRef);
  }

  get mapElement() {
    return (this.$refs.mapEl as HTMLElement)
  }

  deactivated() {
    this.deactivatedTab = !this.history || !this.tracking
  }

  private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {
    this.mapRef = new google.maps.Map(
        element,
        {
          center: this.$config.startCenter,
          zoom: this.$config.startZoom,
        },
    );

    if (bounds) {
      this.map.fitBounds(bounds, 0);
    }

    this.map.data.setStyle({
      strokeWeight: 1,
      strokeOpacity: 1,
      strokeColor: "#3399FF",
      fillColor: "#3399FF",
      fillOpacity: 0.2,
      editable: false,
      draggable: false,
      clickable: true,
      zIndex: 1,
    });
  }

  private _initShape() {

    const options: any = {
      strokeColor: "00ffb7",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "00ffb7",
      fillOpacity: 0.35
    };
    (this as any).loadShape = new google.maps.Polygon(options);
    (this as any).dumpShape = new google.maps.Polygon(options);
  }

  async refresh() {
    await this._loadhistory();
    await this._loadTracking();

    this.setSteps(this.statusBarWidth())
  }

  private getDimensions() {
    setTimeout(() => {
      this.setSteps(this.statusBarWidth())
    }, 500)
  }

  statusBarWidth(): number {
    return (this.$refs.status_bar as HTMLInputElement).clientWidth
  }

  @Watch('tracking')
  onTrackingChange(newValue) {
    if (newValue) {
      this.$nextTick(() => {
        this.setSteps(this.statusBarWidth())
      })
    }
  }

  async mounted() {
    window.addEventListener('resize', this.getDimensions);
  }

  async unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  }


  private _initMarker() {
    this.marker ??= new google.maps.Marker();

    this.moveMarker();

    if (!this.marker?.getMap()) {
      this.marker.setMap(this.map);
    }
  }

  getStatusLabel(step) {
    if (step.status === TicketStatusEnum.DUMPED && step.is_free_dumped) {
      return this.$t(`ticket.statuses.FREE_DUMPED`)
    }
    return this.$t(`ticket.statuses.${TicketStatusEnum[step.status]}`)
  }

  private _drawTraking() {
    const bounds = new google.maps.LatLngBounds();

    this.tracking.forEach((x) => {
      bounds.extend(x)
    });

    this.map.fitBounds(bounds);

    this.map.data.add({
      geometry: new google.maps.Data.LineString(
          this.tracking.map(x => ({lat: x.lat, lng: x.lng}))
      ),
    });

  }


  tracking: Tracking[] = null;

  private _loadTracking() {
    return this.$waitFor(async () => {
      this.tracking = await trackingService.indexAll({
        filters: {
          ticket_id: {
            value: this.ticketId,
            matchMode: FilterMatchMode.EQUALS
          }
        }
      });
        /*
        this.history.forEach((step: TicketHistory) => {
            if(step.lat != null && this.isValidStep(step.status)){
              const t: Tracking = {
                created_at: step.created_at,
                is_free_dumped: false,//step.is_free_dumped, //todo update model or remove field from here 
                lat: step.lat,
                lng: step.lng,
                status: step.status,
                ticket_id: this.ticketId,
                user_id: null,
                //is_from_history: true //todo update model or remove field from here 
              };
            
              this.tracking.push(t);
            }
         });
         this.tracking = this.tracking.sort((a, b) => a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0);
         */
    })
  }

  history: TicketHistory[] = null;

  private _loadhistory() {
    return this.$waitFor(async () => {
      this.history = await ticketsService.history(this.ticketId);
    })
  }

  changeStep(stepIndex: number) {
    this.sliderIndex = stepIndex
  }

  private setSteps(sliderWidth?: number) {

    if (this.sliderSteps.length) {
      this.sliderSteps.forEach((step) => {
        const mt = this.tracking.length / step.index
        step.position = (sliderWidth / mt).toFixed(2)
      })
    } else if (this.tracking.length) {
      let currentStatus: number

      this.history.forEach((step: any) => {
        if (this.isValidStep(step.status)) {
          let diffDate
          this.tracking.forEach((tracking: Tracking, index) => {
            if ((currentStatus !== tracking.status) && this.isValidStep(tracking.status)) {
              currentStatus = tracking.status
              const diff: number = Math.abs(new Date(step.created_at).getTime() - new Date(tracking.created_at).getTime())
              if (!diffDate || diff < diffDate[0]) {
                diffDate = [diff, index]
              }
            }
          })
          const date = step.created_at;
          const mt = this.tracking.length / diffDate[1]
          this.sliderSteps.push({
            status: step.status,
            is_free_dumped: step.is_free_dumped,
            id: step.id,
            index: diffDate[1],
            time: timezone.tz(date, this.timeZone).format("hh:mm A"),
            position: (sliderWidth / mt).toFixed(2)
          })
        }
      })
    }
  }

  private _showOnMap(zone: Zone, center?) {

    const path = zone.shape.map(x =>
        new google.maps.LatLng({lat: x[1], lng: x[0]})
    );

    let shape

    if (zone.zone_type === ZoneTypeEnum.LOAD) {
      shape = (this as any).loadShape
    } else {
      shape = (this as any).dumpShape
    }
    shape.setPath(path);
    shape.setMap(this.map);


    const bounds = new google.maps.LatLngBounds();

    path.forEach((x) => {
      bounds.extend(x)
    });

    new google.maps.Marker({
      position: bounds.getCenter(),
      map: this.map,
      label: {
        text: zone.zone_type === ZoneTypeEnum.LOAD ? 'LOAD ZONE' : 'DUMP ZONE',
        color: "#FFFFFF",
        fontWeight: 'bold'
      },
      icon: {
        url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjyHQt+g8ABFsCIF75EPIAAAAASUVORK5CYII="
      }
    })

    if (center) {
      this.map.fitBounds(bounds);
    }
  }

  private _loadZone(zone, zoneId) {
    return this.$waitFor(async () => {
      this.zones[zone] = await zonesService.getById(+zoneId)
      this.zones[zone].zone_type = ZoneTypeEnum[zone.toUpperCase()]
    })
  }

  async created() {
    if (this.ticketId) {
      await this._loadhistory();
      await this._loadTracking();
      
      try{
        await this._loadZone('dump', this.ticket.shift.zone_dump_id);
      }catch (error) {
        this.zones['dump'] = {'shape' : []};
        
        console.error("invalid dump zone "+this.ticket.shift.zone_dump_id)
      }
      try{
        await this._loadZone('load', this.ticket.shift.zone_load_id);
      }catch (error) {
        this.zones['load'] = {'shape' : []};
        console.error("invalid load zone "+this.ticket.shift.zone_load_id)
      }
      


      if (document.body.contains(this.mapElement)) {
        this._buildMap(this.mapElement);
        this._initShape();
        this._drawTraking();
        this._initMarker();
        this._showOnMap(this.zones['load'])
        this._showOnMap(this.zones['dump'], true)
      }
    }
  }
}
