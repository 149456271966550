export enum SessionStorageEnum {
    TABLE_STATE_USERS = "DT_USERS_STATE",
    TABLE_STATE_ADMINS = "DT_ADMINS_STATE",
    TABLE_STATE_ZONES = "DT_ZONES_STATE",
    TABLE_STATE_CONTRACTS = "DT_CONTRACTS_STATE",
    TABLE_STATE_BROKERS = "DT_BROKERS_STATE",
    TABLE_STATE_CLIENTS = "DT_CLIENTS_STATE",
    TABLE_STATE_CUSTOMERS = "DT_CUSTOMERS_STATE",

    TABLE_STATE_WRS = "DT_WRS_STATE",
    WRS_ADVANCED_FILTERS = "WRS_ADVANCED_FILTERS",

    TABLE_STATE_TICKETS = "DT_TICKETS_STATE",
    TICKETS_ADVANCED_FILTERS = "TICKETS_ADVANCED_FILTERS",

    TABLE_STATE_SHIFTS = "DT_SHIFTS_STATE",
    SHIFTS_ADVANCED_FILTERS = "SHIFTS_ADVANCED_FILTERS",

    TABLE_STATE_INVOICE_MOVEMENTS = "DT_INVOICE_MOVEMENTS_STATE",
    INVOICE_MOVEMENTS_ADVANCED_FILTERS = "INVOICE_MOVEMENTS_ADVANCED_FILTERS",

    TABLE_STATE_INVOICES = "DT_INVOICES_STATE",
    INVOICES_ADVANCED_FILTERS = "INVOICES_ADVANCED_FILTERS",

    TABLE_STATE_NOTIFICATIONS = "DT_NOTIFICATIONS_STATE",
    NOTIFICATIONS_ADVANCED_FILTERS = "NOTIFICATIONS_ADVANCED_FILTERS",

    TABLE_STATE_PROJECTS = "DT_PROJECTS_STATE",
    PROJECTS_ADVANCED_FILTERS = "PROJECTS_ADVANCED_FILTERS",

    TABLE_STATE_ACTIVITIES = "DT_ACTIVITIES_STATE",
    ACTIVITIES_ADVANCED_FILTERS = "ACTIVITIES_ADVANCED_FILTERS",

    TABLE_STATE_APP_SHIFTS = "DT_APP_SHIFTS_STATE",
    APP_SHIFTS_ADVANCED_FILTERS = "APP_SHIFTS_ADVANCED_FILTERS",

    TABLE_STATE_SHIFT_TICKETS = "DT_SHIFT_TICKETS_STATE",

    TABLE_STATE_TEXTS = "TABLE_STATE_TEXTS",
    TEXTS_ADVANCED_FILTERS = "TEXTS_ADVANCED_FILTERS",
}
